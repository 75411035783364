export const PURCHASE_TABLE = "purchaseTable";


export const REPORT_OPTIONS = [
  { name: "Purchase report", value: "purchaseTable" },
  { name: "Subscriptions report", value: "subscriptionsCurrentTable" },
  { name: "Subscriptions Details report", value: "subscriptionsTable" },
  { name: "Incentive report", value: "incentiveTable" },
  { name: "Unredeemed points report", value: "unredemptionTable" },
  { name: "New User report", value: "newsubscriptionsTable" },
  { name: "Referrals report", value: "referTable" },
];

export const REQUEST_TABLE_OPTIONS = [
  { name: "Redemption Requests", value: "allRedeemptionData" },
  { name: "Business Points Requests", value: "allRequestedPointsData" },
];