import React, { useEffect, useState } from "react";
import CommonTable from "./CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { Arrow } from "../icons";
import { formatDate } from "../../utils/helper";
import CustomButton from "../forms/CustomButton";
import {
  getRedeemptionData,
  getRequestPointsData,
  redemptionApprove,
  redemptionReject,
  requestPointApprove,
  requestPointReject,
  setButtonLoader,
  setRejectLoader,
} from "../../Redux/redeemptionSlice";
import { closeModal, openModal } from "../../Redux/commonSlice";

const RedeemRequestReportTable = () => {
  const dispatch = useDispatch();
  const { isLoading, tableData, buttonLoading, rejectButtonLoading } =
    useSelector((state) => ({
      isLoading: state.redeemptionSlice.isLoading,
      buttonLoading: state.redeemptionSlice.buttonLoading,
      rejectButtonLoading: state.redeemptionSlice.rejectButtonLoading,
      tableData: state.redeemptionSlice.allRedeemptionData.tableData,
    }));

  const handleApprove = (e, data) => {
    dispatch(
      openModal({
        type: "confirm",
        message: `Are you sure, you want to accept ${data.points} redeem points request for ${data.name} ?`,
        handleConfirm: () => {
          dispatch(redemptionApprove({ member_id: [e.target.id] }));
          dispatch(closeModal());
        },
      })
    );
  };

  const handleReject = (e, data) => {
    dispatch(
      openModal({
        type: "confirm",
        message: `Are you sure, you want to reject ${data.points} redeem points request for ${data.name} ?`,
        handleConfirm: () => {
          dispatch(redemptionReject({ member_id: [e.target.id] }));
          dispatch(closeModal());
        },
      })
    );
  };

  const columns = React.useMemo(
    (type) => [
      {
        Header: "Serial No",
        id: "serial_no",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Name",
        id: "name",
        accessor: "name",
      },
      {
        Header: "From Email",
        id: "email",
        accessor: "email",
      },
      {
        Header: "Redeem Points",
        id: "points",
        accessor: (row) => {
          if (Number(row?.points) === 0) return row?.points;
          else {
            if (Number(row?.points).toFixed(1) % 1 === 0)
              return parseInt(Number(row?.points).toFixed(1));
            else {
              return parseFloat(Number(row?.points).toFixed(1));
            }
          }
        },
        // accessor: "points",
      },
      {
        Header: "Status",
        id: "status",
        accessor: "status",
      },
      {
        Header: "Request Date",
        id: "requested_at",
        accessor: ({ requested_at }) => {
          const date = new Date(requested_at);
          return (
            date.toLocaleDateString("en-GB") +
            ", " +
            date.toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })
          );
        },
      },
      {
        Header: "Approval",
        id: "",
        accessor: ({ member_id, name, points }) => {
          return (
            <div className="flex gap-3">
              <CustomButton
                id={member_id}
                onClick={(e) => handleApprove(e, { name, points })}
                buttonStyle="text-sm px-3 rounded-[10px] bg-[#DD69AA] text-black font-bold py-1 md:py-2"
              >
                Accept
              </CustomButton>
              <CustomButton
                id={member_id}
                onClick={(e) => handleReject(e, { name, points })}
                buttonStyle="text-sm px-3 rounded-[10px] text-[#DD69AA] border border-[#DD69AA] font-bold py-1 md:py-2"
              >
                Reject
              </CustomButton>
            </div>
          );
        },
      },
    ],
    [buttonLoading, rejectButtonLoading]
  );
  useEffect(() => {
    dispatch(getRedeemptionData());
  }, []);

  return (
    <div className="mt-[36px] w-full">
      {/* <h1 className="text-white text-3xl pb-8">Redemption Request</h1> */}
      {Array.isArray(tableData) && (
        <>
          <CommonTable
            columns={
              tableData ? columns : [{ Header: "", accessor: "no_data" }]
            }
            data={tableData || [{ no_data: "No Data" }]}
            HeaderClasses="bg-[#040404] text-[#DD69AA]"
            HeadingClasses="relative py-[10px] px-[15px] 2xl:pr-[30px] 2xl:pl-0 whitespace-nowrap text-[16px] 2xl:text-[20px] leading-[16px] 2xl:leading-[24px] font-[500]  -tracking-[0.02em]"
            cellDefaultStyle="text-[16px] 2xl:text-xl leading-[16px] 2xl:leading-[36.33px] px-[15px] 2xl:pr-[30px] 2xl:pl-0 font-normal py-[10px] 2xl:py-[9px] -tracking-[2%] text-center"
            tableClasses="w-full rounded-[20px]  overflow-hidden"
            BodyClasses="text-[#A6A6A6]  bg-[#101010]"
            containerClasses="h-max overflow-auto"
            trunctateCells={["name", "email"]}
            headerClasses={{
              fromTo: { textAlign: "left" },
              serial_no: { paddingLeft: "20px" },
            }}
            cellTextClassName={{
              fromTo: { justifyContent: "left", width: "100%" },
            }}
            cellClasses={{
              timestamp: { color: "#A6A6A6" },
            }}
          />
        </>
      )}
    </div>
  );
};

export default RedeemRequestReportTable;
