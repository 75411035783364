import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  getRedeemptionDataApi,
  getRequestPointsDataApi,
  redemptionApproveApi,
  redemptionRejectApi,
  requestPointApproveApi,
  requestPointRejectApi,
} from "../utils/apis/redeemption";
import { REQUEST_TABLE_OPTIONS } from "../utils/Const";
import { showErrorToast, showSuccessToast } from "../utils/helper";


const initTable = {
  isLoading:false,
  tableData: null,
  pagination: null,
  currentPage: 1,
  pageSize: 10,
}

const initialState = {
  isLoading: false,
  buttonLoading: null,
  currentTable: REQUEST_TABLE_OPTIONS[0],
  rejectButtonLoading: null,
  allRedeemptionData: initTable,
  allRequestedPointsData: initTable,
};

export const getRedeemptionData = createAsyncThunk(
  "redeemption/getAll",
  getRedeemptionDataApi
);
export const redemptionApprove = createAsyncThunk(
  "/redeem/approve",
  redemptionApproveApi
);
export const redemptionReject = createAsyncThunk(
  "/redeem/reject",
  redemptionRejectApi
);
export const getRequestPointsData = createAsyncThunk(
  "points/request/getAll",
  getRequestPointsDataApi
);
export const requestPointApprove = createAsyncThunk(
  "/points/approve",
  requestPointApproveApi
);
export const requestPointReject = createAsyncThunk(
  "/points/reject",
  requestPointRejectApi
);

const redeemptionSlice = createSlice({
  name: "redeemptionSlice",
  initialState,
  reducers: {
    setButtonLoader: (state, { payload }) => {
      state.buttonLoading = payload;
      state.rejectButtonLoading = null;
    },
    setRejectLoader: (state, { payload }) => {
      state.rejectButtonLoading = payload;
      state.buttonLoading = null;
    },
    setTableType: (state, { payload }) => {
      state.currentTable = payload;
    },
    resetTable: (state, {payload}) => {
      if(payload?.table) {
        state[payload?.table] = initTable;
      }
    },
    
  },
  extraReducers: {
    [getRedeemptionData.pending]: (state, { payload }) => {
      state.allRedeemptionData.isLoading = true;
    },
    [getRedeemptionData.fulfilled]: (state, { payload }) => {
      state.allRedeemptionData.isLoading = false;
      state.allRedeemptionData.tableData = payload?.result[0];
    },
    [getRedeemptionData.rejected]: (state, { payload }) => {
      state.allRedeemptionData.isLoading = false;
    },

    [redemptionApprove.pending]: (state) => {
      state.isLoading = true;
    },
    [redemptionApprove.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      showSuccessToast(payload?.message || "Success");
    },
    [redemptionApprove.rejected]: (state, { payload }) => {
      state.isLoading = false;
      showErrorToast(payload || "Something went wrong.");
    },

    [redemptionReject.pending]: (state) => {
      state.isLoading = true;
    },
    [redemptionReject.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      showSuccessToast(payload?.message || "Success");
    },
    [redemptionReject.rejected]: (state, { payload }) => {
      state.isLoading = false;
      showErrorToast(payload || "Something went wrong.");
    },

    [getRequestPointsData.pending]: (state, { payload }) => {
      state.allRequestedPointsData.isLoading = true;
    },
    [getRequestPointsData.fulfilled]: (state, { payload }) => {
      state.allRequestedPointsData.isLoading = false;
      state.allRequestedPointsData.tableData = payload?.result[0];
    },
    [getRequestPointsData.rejected]: (state, { payload }) => {
      state.allRequestedPointsData.isLoading = false;
    },

    [requestPointApprove.pending]: (state) => {
      state.isLoading = true;
    },
    [requestPointApprove.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      showSuccessToast(payload?.message || "Success");
    },
    [requestPointApprove.rejected]: (state, { payload }) => {
      state.isLoading = false;
      showErrorToast(payload || "Something went wrong.");
    },

    [requestPointReject.pending]: (state) => {
      state.isLoading = true;
    },
    [requestPointReject.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      showSuccessToast(payload?.message || "Success");
    },
    [requestPointReject.rejected]: (state, { payload }) => {
      state.isLoading = false;
      showErrorToast(payload || "Something went wrong.");
    },
  },
});

export const { setButtonLoader, setRejectLoader, setTableType,resetTable } =
  redeemptionSlice.actions;

export default redeemptionSlice.reducer;
