import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import vector from '../../assets/img/Vector.svg'
import classNames from 'classnames'

const SelectField = ({ setValues, selected, handleChange, cursorNotAllowed, ...props }) => {
    return (
      <div className="" onClick={(e) => e.stopPropagation()}>
        <Listbox
          value={selected}
          onChange={handleChange || (() => {})}
          disabled={props?.disabled || false}
        >
          <div className="relative mt-1">
            <Listbox.Button
              className={classNames(
                "relative shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 text-sm ",
                props?.disabled && "cursor-not-allowed",
                props.inputstyle
              )}
            >
              <span
                className={classNames( props?.selectedTextClassNames ||
                  "block truncate text-left text-sm  xl:text-[20px] 2xl:text-2xl",
                  { "text-gray-500": props?.disabled || false }
                )}
              >
                {selected?.name}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 xl:pr-4 ">
                {props?.dropIcon || (
                  <img
                    className="w-[9.5px] xl:w-[16px] 2xl:w-[27px]"
                    src={vector}
                    alt="v"
                  />
                )}
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 z-10 max-h-max w-full overflow-auto rounded-[10px] xl:py-3 text-sm leading-5 font-normal xl:text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm bg-[#303030] xl:bg-[#101010]">
                {props?.options &&
                  props?.options.map((person, personIdx) => (
                    <Listbox.Option
                      key={personIdx}
                      className={({ active }) =>
                        `relative cursor-default group select-none pl-4 md:pl-5 pr-4 ${
                          active
                            ? "bg-[#DD69AA] text-amber-900"
                            : "text-gray-900"
                        }`
                      }
                      value={person}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block group-last:border-none py-1 xl:py-3 border-[#545557] border-b border-solid truncate text-left  text-white text-[12px] md:text-sm xl:text-[16px] leading-[24px] 4xl:text-[22px] ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {person.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    );
}

export default SelectField