import React, { useEffect, useState } from "react";
import CustomButton from "../forms/CustomButton";
import classNames from "classnames";
import Dropdown from "../forms/Dropdown";
import { Formik } from "formik";
import { ArrowDown } from "../icons";
import { Download } from "../icons";
import ReactDatePicker from "react-datepicker";
import { REPORT_OPTIONS, REQUEST_TABLE_OPTIONS } from "../../utils/Const";
import SelectField from "../forms/SelectField";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, setDefaultDate, testExcel } from "../../utils/helper";
import {
  setTableType,
} from "../../Redux/redeemptionSlice";
import { isFuture, isAfter, isEqual } from "date-fns";
import { closeModal, setCurrentPage } from "../../Redux/commonSlice";
import CustomModal from "../CustomModal";
import ConfirmationModal from "../modal/ConfirmationModal";

const ApprovalRequestHeader = (type) => {
  const dispatch = useDispatch();
  const { currentTable } = useSelector((state) => ({
    currentTable: state.redeemptionSlice.currentTable,
  }));

  useEffect(() => {}, [currentTable]);

  return (
    <div >
      <div className="md:mt-9">
        <p className="pb-10 text-white whitespace-pre-wrap 2xl:whitespace-nowrap 2xl:leading-[42px] leading-5 text-xl max-w-[65%] md:max-w-none md:text-lg lg:text-xl xl:text-[28px] 4xl:text-[40px]">
        Requests 
        </p>
        <div className="gap-[10px] md:gap-6 items-center justify-start flex xl:hidde">
          <Formik
            initialValues={{ report_type: "" }}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <SelectField
                  inputstyle="w-screen max-w-[200px] md:max-w-[250px] xl:w-[300px] 2xl:min-w-[300px]  4xl:min-w-[400px] 
                  border-solid border-[#DD69AA] border 
                  px-3 2xl:px-4 4xl:py-4 py-[6px] lg:py-2 
                  text-[12px] xl:text-[10px] 2xl:text-sm leading-[16px] 2xl:leading-6 
                  outline-none flex items-center bg-[#101010] text-[#DD69AA]
                  rounded-[5px] md:rounded-[10px] xl:rounded-xl"
                  options={REQUEST_TABLE_OPTIONS}
                  selectedTextClassNames="text-[12px] leading-[16px]  xl:text-sm xl:leading-[20px] 2xl:text-lg 4xl:text-[22px] whitespace-nowrap"
                  selected={currentTable}
                  id="report_type"
                  handleChange={(data) => {
                    dispatch(setTableType(data));
                  }}
                  name="report_type"
                  dropIcon={
                    <ArrowDown className="text-[#DD69AA] w-3 h-3 md:w-4 md:h-4 2xl:w-6 2xl:h-6" />
                  }
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ApprovalRequestHeader;
