export const SearchIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8.11529"
        cy="8.17486"
        r="7.41529"
        stroke="#A6A6A6"
        strokeWidth="1.4"
      />
      <line
        x1="16.8637"
        y1="17.913"
        x2="12.9845"
        y2="14.0338"
        stroke="#A6A6A6"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
    </svg>
  );
};
