export const Message = ({ className }) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0506422 2.00106C0.198671 2.00845 0.347329 2.049 0.485643 2.12584L9 6.85604L17.5144 2.12584C17.6527 2.049 17.8013 2.00846 17.9494 2.00106C17.8855 1.35042 17.741 0.912555 17.4142 0.585786C16.8284 0 15.8856 0 14 0H4C2.11438 0 1.17157 0 0.585786 0.585786C0.259018 0.912555 0.114531 1.35042 0.0506422 2.00106ZM18 4.14396L9.97129 8.60436C9.36724 8.93994 8.63276 8.93994 8.02871 8.60436L0 4.14396V10C0 11.8856 0 12.8284 0.585786 13.4142C1.17157 14 2.11438 14 4 14H14C15.8856 14 16.8284 14 17.4142 13.4142C18 12.8284 18 11.8856 18 10V4.14396Z"
        fill="#DD69AA"
      />
    </svg>
  );
};
