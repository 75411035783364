export const Download = ({ className }) => {
    return (
        <svg
        className={className}
         stroke="currentColor"
         fill="currentColor"
        stroke-width="0"
        height="1em" 
        viewBox="0 0 24 24" 
        width="1em" 
        xmlns="http://www.w3.org/2000/svg">
            <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm-1 8V6h2v4h3l-4 4-4-4h3zm6 7H7v-2h10v2z"></path>
        </svg>
    );
  };