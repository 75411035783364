export const MobMenu = ({ className }) => {
  return (
    <svg
      className={className}
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="1.75"
        x2="27.8126"
        y2="1.75"
        stroke="currentColor"
        strokeWidth="2.5"
      />
      <line
        y1="10.8364"
        x2="27.8126"
        y2="10.8364"
        stroke="currentColor"
        strokeWidth="2.5"
      />
      <line
        y1="19.9229"
        x2="27.8126"
        y2="19.9229"
        stroke="currentColor"
        strokeWidth="2.5"
      />
    </svg>
  );
};
