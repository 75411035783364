import React from 'react'
import { AdminLayout } from '../layout/AdminLayout'
import ReportPoints from '../table/ReportPoints';
import SubscriberReport from '../table/SubscriberReport';
import ReportsHeader from '../layout/ReportsHeader';
import { useSelector } from 'react-redux';
import IncentiveReport from '../table/IncentiveReport';
import SubscriberCurrentReport from '../table/SubscriberCurrentReport';
import UnredemptionReport from '../table/UnredemptionReport';
import NewSubscriptionsReport from '../table/NewSubscriptionsReport';
import ReferReport from '../table/ReferReport';

const Reports = () => {
    const { currentTable } = useSelector(state => state.reportSlice);
    return (
        <AdminLayout  type="reports">
            <ReportsHeader />
            {currentTable?.value === "purchaseTable" && <ReportPoints />}
            {currentTable?.value === "subscriptionsCurrentTable" && <SubscriberReport />}
            {currentTable?.value === "subscriptionsTable" && <SubscriberCurrentReport />}
            {currentTable?.value === "incentiveTable" && <IncentiveReport />}
            {currentTable?.value === "unredemptionTable" && <UnredemptionReport />}
            {currentTable?.value === "newsubscriptionsTable" && <NewSubscriptionsReport />}
            {currentTable?.value === "referTable" && <ReferReport />}
        </AdminLayout>
    );
}

export default Reports