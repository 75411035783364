import React from 'react'
import { AdminLayout } from '../layout/AdminLayout'

const AdminInfo = () => {
    return (
        <AdminLayout>
            <div className='text-white'>AdminInfo</div>
        </AdminLayout>
    );
}
export default AdminInfo;