import ApiMiddleware from "../ApiMiddleware";

export const getBusinessSubscriptionsCurrentApi = async (params, { rejectWithValue }) => {
  try {
    const query = [];
    params?.start_date && query.push("start_date="+params?.start_date);
    params?.end_date && query.push("end_date="+params?.end_date);
    params?.page && query.push("page="+params?.page);
    params?.pageSize && query.push("page_size="+params?.pageSize);
    const response = await ApiMiddleware.get(`admin/reports/current/subscriptions/?${query.join('&')}`);
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw rejectWithValue(error);
    }
    throw rejectWithValue(error.response.data.message);
  }
}

export const getBusinessSubscriptionsReportsApi = async (params, { rejectWithValue }) => {
  try {
    const query = [];
    params?.start_date && query.push("start_date="+params?.start_date);
    params?.end_date && query.push("end_date="+params?.end_date);
    params?.page && query.push("page="+params?.page);
    params?.pageSize && query.push("page_size="+params?.pageSize);
    const response = await ApiMiddleware.get(`admin/reports/current/subscriptions/details/?${query.join('&')}`);
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw rejectWithValue(error);
    }
    throw rejectWithValue(error.response.data.message);
  }
}

export const getBusinessPurchaseReportsApi = async (params, { rejectWithValue }) => {
  try {
    const query = [];
    params?.start_date && query.push("start_date="+params?.start_date);
    params?.end_date && query.push("end_date="+params?.end_date);
    params?.page && query.push("page="+params?.page);
    params?.pageSize && query.push("page_size="+params?.pageSize);
    const response = await ApiMiddleware.get(`admin/reports/business/purchase/history/?${query.join('&')}`);
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw rejectWithValue(error);
    }
    throw rejectWithValue(error.response.data.message);
  }
}

export const getBusinessIncentiveReportsApi = async (params, { rejectWithValue }) => {
  try {
    const query = [];
    params?.start_date && query.push("start_date="+params?.start_date);
    params?.end_date && query.push("end_date="+params?.end_date);
    params?.page && query.push("page="+params?.page);
    params?.pageSize && query.push("page_size="+params?.pageSize);
    const response = await ApiMiddleware.get(`admin/reports/incentive/?${query.join('&')}`);
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw rejectWithValue(error);
    }
    throw rejectWithValue(error.response.data.message);
  }
}

export const getBusinessUnredemptionReportsApi = async (params, { rejectWithValue }) => {
  try {
    const query = [];
    params?.start_date && query.push("start_date="+params?.start_date);
    params?.end_date && query.push("end_date="+params?.end_date);
    params?.page && query.push("page="+params?.page);
    params?.pageSize && query.push("page_size="+params?.pageSize);
    const response = await ApiMiddleware.get(`admin/reports/unreedemed/points/?${query.join('&')}`);
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw rejectWithValue(error);
    }
    throw rejectWithValue(error.response.data.message);
  }
}

export const getBusinessNewSubscriptionsReportApi = async (params, { rejectWithValue }) => {
  try {
    const query = [];
    params?.start_date && query.push("start_date="+params?.start_date);
    params?.end_date && query.push("end_date="+params?.end_date);
    params?.page && query.push("page="+params?.page);
    params?.pageSize && query.push("page_size="+params?.pageSize);
    const response = await ApiMiddleware.get(`admin/reports/new/subscriptions/?${query.join('&')}`);
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw rejectWithValue(error);
    }
    throw rejectWithValue(error.response.data.message);
  }
}

export const getBusinessReferReportApi = async (params, { rejectWithValue }) => {
  try {
    const query = [];
    params?.start_date && query.push("start_date="+params?.start_date);
    params?.end_date && query.push("end_date="+params?.end_date);
    params?.page && query.push("page="+params?.page);
    params?.pageSize && query.push("page_size="+params?.pageSize);
    const response = await ApiMiddleware.get(`admin/report/refer/?${query.join('&')}`);
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw rejectWithValue(error);
    }
    throw rejectWithValue(error.response.data.message);
  }
}