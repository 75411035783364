import React, { useEffect, useState } from 'react'
import CommonTable from './CommonTable';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Arrow } from '../icons';
import { changeTablePage,   getBusinessReferReport } from '../../Redux/reportSlice';
import { formatDate } from '../../utils/helper';

const ReferReport = () => {
  const dispatch = useDispatch();
  const {currentTable, pageSize, currentPage, pagination, isLoading, filters, tableData} = useSelector(state => ({
    currentTable : state.reportSlice.currentTable,
    isLoading: state.reportSlice.referTable.isLoading,
    pageSize: state.reportSlice.referTable.pageSize,
    currentPage: state.reportSlice.referTable.currentPage,
    pagination: state.reportSlice.referTable.pagination,
    filters: state.reportSlice.referTable.filters,
    tableData: state.reportSlice.referTable.tableData,
  }));

  const columns = React.useMemo(() => [
    {
      Header: "Serial No",
      id: "serial_no",
      accessor: (row, i) => i + 1
    },
    {
      Header: "Business",
      id: "business_name",
      accessor: (rows) => {
        return rows?.business_name === "" ? "-": rows?.business_name;
      },
    },
    {
      Header: "Referrer (name)",
      id: "referrer_name",
      accessor: "referrer_name"
    },
    {
      Header: "Referrer (Email id)",
      id: "referrer_email",
      accessor: "referrer_email"
    },
    {
      Header: "Referred (name)",
      id: "referred_to_name",
      accessor: "referred_to_name"
    },
    {
      Header: "Referred (Email id) ",
      id: "referred_to_email",
      accessor: "referred_to_email"
    },
    {
      Header: " Date & time",
      id: "invited_at",
      accessor: ({invited_at}) => {
        const date = new Date(invited_at);
        return date.toLocaleDateString('en-GB') + ", "+ date.toLocaleTimeString('en-US',{
          hour: '2-digit',
          minute:'2-digit'
        });
      },
    },
    {
      Header: "Status",
      id: "joined_at",
      accessor: (row) => {
        return row?.joined_at === null ? "Pending": "Joined";
      },
    },
  ], []);
  const [data, setData] = useState(null);
  const handlePageClick = (page) => {
    dispatch(changeTablePage({table: currentTable.value, page:page.selected + 1}));
  };
  useEffect(() => {
    dispatch(getBusinessReferReport());
  }, []);
  useEffect(() => {
    const start_date = filters?.start_date && filters?.start_date !== 'null' ? formatDate(filters?.start_date) : null; 
    const end_date = filters?.end_date && filters?.end_date !== 'null' ? formatDate(filters?.end_date) : null; 
    dispatch(getBusinessReferReport({page: currentPage, pageSize, start_date, end_date}));
  }, [filters, currentPage, pageSize, dispatch]);
  
  return (
    <div className="mt-[36px] lg:mt-[50px] 4xl:mt-[56px]  w-full">
      {Array.isArray(tableData) &&
        <>
        <CommonTable
          columns={tableData ? columns : [{ Header: "", accessor: "no_data" }]}
          data={tableData || [{ no_data: "No Data" }]}
          HeaderClasses="bg-[#040404] text-[#DD69AA]"
            HeadingClasses="relative py-[10px] px-[15px] 2xl:pr-[30px] 2xl:pl-0 whitespace-nowrap text-[16px] 2xl:text-[20px] leading-[16px] 2xl:leading-[24px] font-[500]  -tracking-[0.02em]"
            cellDefaultStyle="text-[16px] 2xl:text-xl leading-[16px] 2xl:leading-[36.33px] px-[15px] 2xl:pr-[30px] 2xl:pl-0 font-normal py-[10px] 2xl:py-[9px] -tracking-[2%] text-center"
            tableClasses="w-full rounded-[20px] overflow-hidden"
            BodyClasses="text-[#A6A6A6] bg-[#101010]"
            containerClasses="h-max overflow-auto"
          trunctateCells={['business_name', 'referrer_name','referrer_email','referred_to_name','referred_to_email']}
            headerClasses={{
              "serial_no": { textAlign: "right" },
              "business_name": { textAlign: "center" },
              "referrer_name": { textAlign: "center" },
              "referrer_email": { textAlign: "center" },
            }}
            cellTextClassName={{
              "business_name": { justifyContent: "center", width: "100%" },
              "referrer_email": { justifyContent: "center" },
            }}
            cellClasses={{
              "referrer_email": { justifyContent: "center" },
            }}
            isLoading={isLoading}
        />
           {pagination &&
            <div className="flex justify-center md:justify-end h-max items-center gap-12">
              <ReactPaginate
                breakLabel=".........."
                nextLabel={
                  <button disabled={!pagination?.next_link} className="group"
                  >
                    <div className="bg-[#DD69AA] md:py-[9px] px-3 md:h-[30px] py-1 group-disabled:bg-gray-500 rounded-[3px] ml-2 md:ml-1 md:rounded-[10px] hover:bg-pink-500">
                      <Arrow className="text-white group-hover:text-black rotate-180" />
                    </div>
                  </button>
                }
                pageRangeDisplayed={1}
                onPageChange={handlePageClick}
                forcePage={currentPage - 1}
                pageCount={pagination?.total_pages || 1}
                marginPagesDisplayed={2}
                previousLabel={
                  <button
                    className="group"
                    disabled={!pagination?.previous_link}
                  >
                    <div className="bg-[#DD69AA] group-disabled:bg-gray-500 md:py-[9px] px-3 md:h-[30px] py-1 group rounded-[3px] mr-2 md:mr-1 md:rounded-[10px] hover:bg-pink-500">
                      <Arrow className="text-white group-hover:text-black" />
                    </div>
                  </button>
                }
                renderOnZeroPageCount={1}
                nextLinkClassName="leading-none flex"
                previousLinkClassName="leading-none flex"
                containerClassName={
                  "flex pt-[17px] pb-[25px] md:py-8 justify-center items-center gap-x-[3px] md:gap-x-1 leading-none"
                }
                pageClassName="cursor-pointer md:rounded-[10px] md:min-w-[30px] md:h-[30px] md:flex md:justify-center md:items-center after:content-[','] last:bg-blue-500 after:ml-1 after:text-[#979998] md:after:content-none"
                pageLinkClassName="font-normal md:px-2 md:py-1 h-full w-full text-center text-[14px] md:text-sm leading-[18.87px] text-[#979998] -tracking-tight"
                breakClassName="text-[#979998] tracking-[2px] md:tracking-[3px] 2xl:tracking-[5px]"
                activeLinkClassName="text-[#FFFFFF]"
                activeClassName="md:bg-[#DD69AA]"
              />
            </div>
          }
        </>
      }
    </div>
  )
}

export default ReferReport