import * as Yup from "yup";

export const LoginValidationSchema = Yup.object({
  username: Yup.string()
    .min(3, "Must be 3 char long.")
    .required("E-mail is required."),
  password: Yup.string()
    .trim()
    .oneOf([Yup.ref("password"), null])
    .min(8, "Must be 8 char long.")
    .required("Password is required."),
});

export const ForgotValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .required("E-mail is required."),
});

export const BulkUploadValidations = Yup.object({
  csv_file: Yup.mixed().required("Select CSV file to upload.").nullable(),
});

export const ResetPasswordValidationSchema = Yup.object({
  password: Yup.string()
    .required("Password is required.")
    .trim()
    .oneOf([Yup.ref("password"), null])
    .min(8, "Must be 8 char long.")
    .required("Password is required."),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match.")
    .trim()
    .min(8, "Must be 8 char long.")
    .required("Password is required."),
});

export const ChangePasswordValidationSchema = Yup.object({
  current_password: Yup.string()
    .required("Password is required.")
    .trim()
    .min(8, "Must be 8 char long.")
    .required("Password is required."),
  password: Yup.string()
    .trim()
    .min(8, "Must be 8 char long.")
    .required("Password is required."),
});

export const AwardPointValidationSchema = Yup.object({
  amount: Yup.number("Points must be number").required("Points are required."),
  reward_type: Yup.string().required("Points type is required.").nullable(),
});

export const BusinessFormValidationSchema = Yup.object({
  business_name: Yup.string()
    .trim()
    .min(2, "Must be 2 char long.")
    .max(100, "Maximum 100 char.")
    .required("Name of the company is required."),
  company_no: Yup.string()
    .trim()
    .max(20, "Maximum 20 char.")
    .required("Company's Number required."),
  owner_name: Yup.string()
    .trim()
    .min(2, "Must be 2 char long.")
    .max(40, "Maximum 40 char.")
    .required("Owner's Name required."),
  owner_email: Yup.string()
    .email("Invalid email address.")
    .required("E-mail is required."),
  vat_no: Yup.string()
    .trim()
    .max(9, "Maximum 9 char.")
    .matches(/^\w+$/u, "Special characters not allowed."),
  address: Yup.string()
    .trim()
    .min(2, "Must be 2 char long.")
    .required("Address is required."),
});

export const CustomerFormValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .min(2, "Must be 2 char long.")
    .max(40, "Maximum 40 char.")
    .required("Name is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("E-mail is required."),
  phone_no: Yup.string()
    .trim()
    .max(20, "Maximum 20 char.")
    .required("Phone Number is required."),
  address: Yup.string()
    .trim()
    .min(2, "Must be 2 char long.")
    .required("Address is required."),
});

export const CustomerEditValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .min(2, "Must be 2 char long.")
    .max(40, "Maximum 40 char.")
    .required("Name is required."),
  phone_no: Yup.string()
    .trim()
    .max(20, "Maximum 20 char.")
    .required("Phone Number is required."),
});

export const MessageFormValidationSchema = Yup.object({
  subject: Yup.string()
    .trim()
    .min(2, "Must be 2 char long.")
    .required("Subject is required."),
  message: Yup.string()
    .trim()
    .min(2, "Must be 2 char long.")
    .required("Message is required."),
});

export const UserSendPointsValidationSchema = Yup.object({
  to_user: Yup.string()
    .trim()
    .email("Invalid email.")
    .required("Email is required."),
  sent_amount: Yup.number().required("Points are required."),
});

export const UserRedeemPointsValidationSchema = Yup.object({
  points: Yup.number().required("Points are required."),
});

export const UserBusinessPointsRequestValidationSchema = Yup.object({
  points: Yup.number().required("Points are required."),
});

export const BusinessEditValidationSchema = Yup.object({
  business_name: Yup.string()
    .trim()
    .min(2, "Must be 2 char long.")
    .max(100, "Maximum 100 char.")
    .required("Name of the company is required."),
  company_no: Yup.string()
    .trim()
    .max(20, "Maximum 20 char.")
    .required("Company's Number required."),
  vat_no: Yup.string()
    .trim()
    .max(9, "Maximum 9 char.")
    .matches(/^\w+$/u, "Special characters not allowed."),
  address: Yup.string()
    .trim()
    .min(2, "Must be 2 char long.")
    .required("Address is required."),
});

export const ReferFriendValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .min(2, "Must be 2 char long.")
    .required("Name is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("E-mail is required."),
});
