export const StarFilled = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.14337 3.62759C7.92998 1.6666 8.32328 0.686096 8.99961 0.686096C9.67593 0.686096 10.0692 1.6666 10.8558 3.6276L10.8925 3.71891C11.3369 4.82678 11.5591 5.38071 12.0119 5.7174C12.4648 6.05409 13.0592 6.10733 14.2481 6.2138L14.4631 6.23305C16.4089 6.40732 17.3818 6.49445 17.59 7.11343C17.7981 7.73242 17.0756 8.38976 15.6306 9.70445L15.1483 10.1432C14.4168 10.8087 14.051 11.1415 13.8806 11.5776C13.8488 11.659 13.8223 11.7423 13.8014 11.8271C13.6894 12.2818 13.7965 12.7645 14.0107 13.73L14.0774 14.0305C14.471 15.8049 14.6679 16.692 14.3242 17.0747C14.1958 17.2177 14.0289 17.3206 13.8434 17.3712C13.3472 17.5066 12.6428 16.9326 11.2338 15.7845C10.3086 15.0306 9.84605 14.6537 9.31495 14.5689C9.10604 14.5355 8.89317 14.5355 8.68426 14.5689C8.15316 14.6537 7.69057 15.0306 6.7654 15.7845C5.35645 16.9326 4.65197 17.5066 4.15577 17.3712C3.97035 17.3206 3.80344 17.2177 3.67502 17.0747C3.33134 16.692 3.52818 15.8049 3.92185 14.0305L3.98853 13.73C4.20274 12.7645 4.30984 12.2818 4.19779 11.8271C4.17688 11.7423 4.15045 11.659 4.11865 11.5776C3.94817 11.1415 3.58242 10.8087 2.85091 10.1432L2.36863 9.70445C0.923588 8.38976 0.201066 7.73242 0.409244 7.11343C0.617423 6.49445 1.59033 6.40732 3.53614 6.23305L3.75108 6.2138C4.94 6.10733 5.53445 6.05409 5.9873 5.7174C6.44015 5.38071 6.66235 4.82678 7.10674 3.71891L7.14337 3.62759Z"
        fill="currentColor"
      />
    </svg>
  );
};

