import classNames from "classnames";
import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, setInstructionsModal } from "../../../Redux/commonSlice";
import { userReferFriend } from "../../../Redux/userSlice";
import { ReferFriendValidationSchema } from "../../../utils/FormValidations";
import CustomButton from "../../forms/CustomButton";
import { InputField } from "../../forms/InputField";
import AdminHeader from "../../layout/AdminHeader";
import { UserLayout } from "../../layout/UserLayout";

const UserRefer = () => {

  const dispatch = useDispatch();
    const { isLoading } = useSelector(state => ({
      isLoading: state.userSlice.referLoading,
    }));

  const initialValues = {name: "", business_name: "", email: ""};

  const handleReferFriend = (values, { resetForm }) => {
    dispatch(userReferFriend(values));
    resetForm();
  };

  return (
    <UserLayout>
      <AdminHeader
        className=""
        showControls={false}
        type="refer-friend"
        title="Refer a Friend"
      />
      <div className="min-w-[304px] max-w-[597px] 2xl:max-w-[1171px] w-[70vw] 3xl:w-full mx-auto pt-6">
        <Formik
          initialValues={initialValues}
          validationSchema={ReferFriendValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleReferFriend}
        >
          {({ values, setFieldValue, handleSubmit}) => (
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="pt-3 pb-6 xl:pt-[14px] xl:pb-7 px-5 xl:px-[31.5px] ">
                <div className="flex text-left flex-col gap-[14px] md:gap-[20px] 2xl:gap-[35px]">
                  <div>
                    <label
                      className="whitespace-nowrap text-base font-medium tracking-tight text-pink-light 
                      pb-[8.47px] 2xl:text-[24px] xl:font-normal 2xl:pb-[20px] block"
                      >
                      Name<span className="text-[#DD69AA]">*</span>
                    </label>
                    <InputField
                      inputstyle="w-full text-[#858383] text-sm md:text-sm 2xl:text-lg font-normal tracking-[-0.02em] outline-none
                                   py-[8px] md:py-[10px] 2xl:py-[10px] rounded-[10px] 2xl:rounded-2xl  border border-[#FFFFFF]/[10%] bg-transparent xl:pl-[25px] pl-[15.56px]"
                      borderstyle="w-full text-[#858383] text-sm md:text-sm 2xl:text-lg font-normal tracking-[-0.02em] outline-none
                                   py-[8px] md:py-[10px] 2xl:py-[10px] rounded-[10px] 2xl:rounded-2xl  border border-red-800 bg-transparent xl:pl-[25px] pl-[15.56px]"
                      errorRight={true}
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                    />
                  </div>
                  {/* <div>
                    <label
                      className="whitespace-nowrap text-base font-medium tracking-tight text-pink-light 
                      pb-[8.47px] 2xl:text-[24px] xl:font-normal 2xl:pb-[20px] block"
                      >
                      Last Name<span className="text-[#DD69AA]">*</span>
                    </label>
                    <InputField
                      inputstyle="w-full text-[#858383] text-sm md:text-sm 2xl:text-lg font-normal tracking-[-0.02em] outline-none
                                   py-[8px] md:py-[10px] 2xl:py-[10px] rounded-[10px] 2xl:rounded-2xl  border border-[#FFFFFF]/[10%] bg-transparent xl:pl-[25px] pl-[15.56px]"
                      borderstyle="w-full text-[#858383] text-sm md:text-sm 2xl:text-lg font-normal tracking-[-0.02em] outline-none
                                   py-[8px] md:py-[10px] 2xl:py-[10px] rounded-[10px] 2xl:rounded-2xl  border border-red-800 bg-transparent xl:pl-[25px] pl-[15.56px]"
                      errorRight={true}
                      type="text"
                      id="last_name"
                      name="last_name"
                      placeholder="Name of the company"
                    />
                  </div> */}
                  <div>
                    <label
                      className="whitespace-nowrap text-base font-medium tracking-tight text-pink-light 
                      pb-[8.47px] 2xl:text-[24px] xl:font-normal 2xl:pb-[20px] block"
                      >
                      Company Name
                    </label>
                    <InputField
                      inputstyle="w-full text-[#858383] text-sm md:text-sm 2xl:text-lg font-normal tracking-[-0.02em] outline-none
                                   py-[8px] md:py-[10px] 2xl:py-[10px] rounded-[10px] 2xl:rounded-2xl  border border-[#FFFFFF]/[10%] bg-transparent xl:pl-[25px] pl-[15.56px]"
                      borderstyle="w-full text-[#858383] text-sm md:text-sm 2xl:text-lg font-normal tracking-[-0.02em] outline-none
                                   py-[8px] md:py-[10px] 2xl:py-[10px] rounded-[10px] 2xl:rounded-2xl  border border-red-800 bg-transparent xl:pl-[25px] pl-[15.56px]"
                      errorRight={true}
                      type="text"
                      id="business_name"
                      name="business_name"
                      placeholder="Name of the company"
                    />
                  </div>
                  <div>
                    <label className="whitespace-nowrap text-base font-medium tracking-tight text-pink-light pb-[8.47px] 2xl:text-[24px] xl:font-normal 2xl:pb-[20px] block">
                      Email ID
                      <span className="text-[#DD69AA]">*</span>
                    </label>
                    <InputField
                      inputstyle="w-full disabled:cursor-not-allowed disabled:bg-[#6B7280] text-[#858383] text-sm md:text-sm 2xl:text-lg font-normal tracking-[-0.02em] outline-none
                                    py-[8px] md:py-[10px] 2xl:py-[10px] rounded-[10px] 2xl:rounded-2xl  border border-[#FFFFFF]/[10%] bg-transparent xl:pl-[25px] pl-[15.56px]"
                      borderstyle="w-full disabled:cursor-not-allowed disabled:bg-[#6B7280]  text-[#858383] text-sm md:text-sm 2xl:text-lg font-normal tracking-[-0.02em] outline-none
                                    py-[8px] md:py-[10px] 2xl:py-[10px] rounded-[10px] 2xl:rounded-2xl  border border-red-800 bg-transparent xl:pl-[25px] pl-[15.56px]"
                      errorRight={true}
                      type="text"
                      id="email"
                      name="email"
                      placeholder="mail@simmmple.com"
                    />
                  </div>
                </div>
                <div>
                  <div className="md:grid grid-cols-2 xl:gap-[34px] gap-[14px] flex justify-center">
                    <CustomButton
                      showLoader={isLoading}
                      disabled={isLoading}
                      loaderSize={20}
                      type="submit"
                      className="text-xl"
                      buttonStyle="w-full py-[7.5px] 2xl:py-[10px] text-xl tracking-tight text-white xl:text-pink-light font-bold rounded-[10px] 2xl:rounded-2xl  
                                    bg-[#DD69AA] mt-[29.6px] xl:mt-8"
                    >Invite</CustomButton>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </UserLayout>
  );
};

export default UserRefer;
