import React from 'react'
import CustomModal from '../CustomModal';
import { CloseFilled } from '../icons';
import { setInstructionsModal } from '../../Redux/commonSlice';
import { useDispatch, useSelector } from 'react-redux'

const InstructionsModal = () => {
  const dispatch = useDispatch();
  const InstructionsModal = useSelector(state => state.commonSlice.InstructionsModal);
  return (
    <CustomModal modal={InstructionsModal} >
      <div className='bg-[#101010] min-w-[300px] rounded-[10px]'>
        <div className='flex items-center border-b-[0.5px] justify-between py-3 px-5 border-solid border-[#545557]'>
          <p className='text-white' >Card Activation Instructions</p>
          <button className='block focus-visible:outline-none' onClick={() => dispatch(setInstructionsModal({isVisible: false, content: null}))}>
            <CloseFilled className="text-[#737373]" />
          </button>
        </div>
        <div className='px-[60px] py-[22px] text-center'>
          {/* <p className='text-5 leading-6 text-[#737373] font-normal'>
            {InstructionsModal?.content}
          </p> */}
          {InstructionsModal?.content}
        </div>
      </div>
    </CustomModal>
  )
}

export default InstructionsModal