import { configureStore } from '@reduxjs/toolkit'
import loginSlice from "./auth/loginSlice";
import commonSlice from "./commonSlice";
import businessSlice from "./businessSlice";
import customerSlice from "./customerSlice";
import userSlice from "./userSlice";
import reportSlice from "./reportSlice";
import redeemptionSlice from "./redeemptionSlice"

const store = configureStore({
    reducer: {
        loginSlice,
        commonSlice,
        businessSlice,
        customerSlice,
        userSlice,
        reportSlice,
        redeemptionSlice,
        devTools: true,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false 
        })
})

export default store