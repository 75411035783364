import React, { useEffect, useState } from 'react'
import CustomButton from '../forms/CustomButton';
import classNames from 'classnames';
import Dropdown from '../forms/Dropdown';
import { Formik } from 'formik';
import { ArrowDown } from '../icons';
import { Download } from '../icons';
import ReactDatePicker from 'react-datepicker';
import { REPORT_OPTIONS } from '../../utils/Const';
import SelectField from '../forms/SelectField';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, setDefaultDate, testExcel } from '../../utils/helper';
import { changeTablePage, clearDateFilter, setDateDefault, setDateFilter, setTableType } from '../../Redux/reportSlice';
import { isFuture, isAfter, isEqual } from 'date-fns';
import { closeModal, setCurrentPage } from '../../Redux/commonSlice';
import CustomModal from '../CustomModal';
import ConfirmationModal from '../modal/ConfirmationModal';

const ReportsHeader = () => {
  const dispatch = useDispatch();
  const { currentTable } = useSelector(state => ({
    currentTable: state.reportSlice.currentTable,
  }));
  const { end_date, start_date } = useSelector(state => ({
    end_date: state.reportSlice[currentTable?.value].filters?.end_date,
    start_date: state.reportSlice[currentTable?.value].filters?.start_date,
  }));
  const [customError, setCustomError] = useState(false);
  
  const handleDownload = () => {
    currentTable?.value === "purchaseTable" && testExcel({fileName: "Purchase", url: `admin/reports/business/purchase/history/download/?start_date=${formatDate(start_date)}&end_date=${formatDate(end_date)}`});
    currentTable?.value === "subscriptionsTable" && testExcel({fileName: "Subscriptions", url: `admin/reports/current/subscriptions/details/download/?start_date=${formatDate(start_date)}&end_date=${formatDate(end_date)}`});
    currentTable?.value === "subscriptionsCurrentTable" && testExcel({fileName: "SubscriptionsDetails", url: `admin/reports/current/subscriptions/download/?start_date=${formatDate(start_date)}&end_date=${formatDate(end_date)}`});
    currentTable?.value === "incentiveTable" && testExcel({fileName: "Incentive", url: `admin/reports/incentive/download/?start_date=${formatDate(start_date)}&end_date=${formatDate(end_date)}`});
    currentTable?.value === "unredemptionTable" && testExcel({fileName: "Unreedemed", url: `admin/reports/unreedemed/points/download/?start_date=${formatDate(start_date)}&end_date=${formatDate(end_date)}`});
    currentTable?.value === "newsubscriptionsTable" && testExcel({fileName: "Newsubscriptions", url: `admin/reports/new/subscriptions/download/?start_date=${formatDate(start_date)}&end_date=${formatDate(end_date)}`});
    currentTable?.value === "referTable" && testExcel({fileName: "Refer", url: `admin/report/refer/download/?start_date=${formatDate(start_date)}&end_date=${formatDate(end_date)}`});
  }

  const hanbleStartDate = (startDate) => {
    dispatch(setDateFilter({table: currentTable?.value, filter: {start_date : startDate || null}}));
  }
  const hanbleEndDate = (endDate) => {
    dispatch(setDateFilter({table: currentTable?.value, filter: {end_date : endDate || null}}));
  }
  
  useEffect(() => {
    dispatch(changeTablePage({table:currentTable?.value, page:1}))
  }, [start_date, end_date])
  
  useEffect(() => {
  }, [currentTable])

  return (
    <div>
      <div className="pb-5 xl:pb-0 md:pt-[36px] 4xl:pt-[72px] flex  md:gap-5 lg:gap-14 justify-between md:items-start lg:items-center w-full items-center">
        <p className="text-white whitespace-pre-wrap 2xl:whitespace-nowrap 2xl:leading-[42px] leading-5 text-xl max-w-[65%] md:max-w-none md:text-lg lg:text-xl xl:text-[28px] 4xl:text-[40px]">
          Reports
        </p>
        <div className="gap-[10px] md:gap-6 items-center flex xl:hidden">
          <Formik
            initialValues={{ report_type: "" }}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <SelectField
                  inputstyle="w-screen max-w-[200px] md:max-w-[250px] xl:w-[300px] 2xl:min-w-[300px]  4xl:min-w-[400px] 
                  border-solid border-[#DD69AA] border 
                  px-3 2xl:px-4 4xl:py-4 py-[6px] lg:py-2 
                  text-[12px] xl:text-[10px] 2xl:text-sm leading-[16px] 2xl:leading-6 
                  outline-none flex items-center bg-[#101010] text-[#DD69AA]
                  rounded-[5px] md:rounded-[10px] xl:rounded-xl"
                  options={REPORT_OPTIONS}
                  selectedTextClassNames="text-[12px] leading-[16px]  xl:text-sm xl:leading-[20px] 2xl:text-lg 4xl:text-[22px] whitespace-nowrap"
                  selected={currentTable}
                  id="report_type"
                  handleChange={(data) => {
                    dispatch(setTableType(data))
                    dispatch(setDateDefault({table: data?.value}))
                  }}
                  name="report_type"
                  dropIcon={<ArrowDown className="text-[#DD69AA] w-3 h-3 md:w-4 md:h-4 2xl:w-6 2xl:h-6" />}
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div className=" items-center sm:justify-end flex w-full ">
        <div className="report-date-picker flex gap-3 lg:flex lg:gap-3 items-end">
        <div className='hidden xl:block'>
          <Formik
            initialValues={{ report_type: "" }}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <SelectField
                  inputstyle="xl:w-[260px] 2xl:min-w-[330px] 4xl:min-w-[400px] 
                  border-solid border-[#DD69AA] border 
                  px-3 2xl:px-4 py-[6px] xl:py-[6px] 2xl:py-[6px] 4xl:py-3
                  text-[12px] xl:text-[10px] 2xl:text-sm leading-[16px] 2xl:leading-6 
                  outline-none flex items-center bg-[#101010] text-[#DD69AA]
                  rounded-[5px] md:rounded-[10px] xl:rounded-xl"
                  options={REPORT_OPTIONS}
                  selectedTextClassNames="text-[12px] leading-[16px]  xl:text-base xl:leading-[24px] 2xl:text-lg 4xl:text-2xl 4xl:leading-[32px] whitespace-nowrap"
                  selected={currentTable}
                  id="report_type"
                  handleChange={(data) => {
                    dispatch(setTableType(data))
                    dispatch(setDateDefault({table: data?.value}))
                  }}
                  name="report_type"
                  dropIcon={<ArrowDown className="text-[#DD69AA] w-3 h-3 md:w-4 md:h-4 2xl:w-6 2xl:h-6" />}
                />
              </form>
            )}
          </Formik>
        </div>
        <div> 
        <label className='text-[#DD69AA] text-base leading-7  lg:text-[18px] lg:leading-8 2xl:text-[22px] 2xl:leading-9 4xl:text-3xl 4xl:leading-[50px]'>Start Date</label>
            <ReactDatePicker
            
              dateFormat="d/MM/ yyyy"
              isClearable
              className="w-full pl-3 bg-[#101010]  text-[14px] leading-[16px]  md:text-sm lg:text-base 2xl:text-lg 4xl:text-2xl  px-1 py-[6px]  2xl:px-6  2xl:py-2  2xl:leading-6 4xl:py-3  text-white focus-visible:outline-none focus-visible:border focus-visible:border-solid focus-visible:border-[#DD69AA] border-[#DD69AA] rounded-[10px] border"
              name="start_date"
              id="start_date"
              placeholderText='Start Date'
              selected={start_date}
              clearButtonClassName="!right-1 md:right-0"             
              onChange={hanbleStartDate}
              calendarClassName="!bg-black"
              filterDate={(d) => !isFuture(d)}
            />
          </div>
          <div className="relative">
            <div>
                 <label className='text-[#DD69AA] text-base leading-7 lg:text-[18px] lg:leading-8 2xl:text-[22px] 2xl:leading-9 4xl:text-3xl 4xl:leading-[50px]'>End Date</label>
              <ReactDatePicker
                dateFormat="d/MM/ yyyy"
                isClearable
                clearButtonClassName='bg-[#DD69AA] text-white !right-1 md:right-0'
                className="w-full pl-3 bg-[#101010] leading-[16px] 2xl:leading-6 px-1 2xl:px-6  py-[6px] 2xl:py-2 4xl:py-3 text-[14px] md:text-sm lg:text-base 2xl:text-lg 4xl:text-2xl text-white focus-visible:outline-none focus-visible:border focus-visible:border-solid focus-visible:border-[#DD69AA] border-[#DD69AA] rounded-[10px] border"
                name="end_date"
                id="end_date"
                placeholderText='End Date'
                selected={end_date}
                onChange={hanbleEndDate}
                calendarClassName="!bg-black"
                maxDate={new Date()}
                filterDate={(d) => {
                  return isEqual(d, start_date) ? true : (!isFuture(d) &&  isAfter(d, start_date))
                }}
                
              />
            </div>
          </div>
        </div>
        <CustomButton
          type="submit"
          onClick={handleDownload}
          buttonStyle={classNames(
            "hidden ml-[12px] md:block mt-[28px] lg:mt-[30px] 2xl:mt-[35px] 4xl:mt-[50px] 4xl:w-screen w-max 4xl:w-full 4xl:max-w-[220px] 4xl:py-3 leading-[16px] 2xl:leading-6  px-3 lg:px-6 2xl:px-9 py-[6px] lg:py-[8px] 2xl:py-2 text-[12px] md:text-sm lg:text-bas 4xl:text-2xl  bg-[#DD69AA] font-medium rounded-[10px]  text-white"
          )}
        >
          Download
        </CustomButton>
        <button  onClick={handleDownload} >
      <Download
      className="mt-[28px]  ml-[12px] text-[#DD69AA] h-[28px] w-[28px] md:hidden " />
      </button>
      </div>
    </div>
  );
}

export default ReportsHeader;