import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import ApiMiddleware from "../utils/ApiMiddleware";
import { getBusinessIncentiveReportsApi, getBusinessNewSubscriptionsReportApi, getBusinessPurchaseReportsApi, getBusinessReferReportApi, getBusinessSubscriptionsCurrentApi, getBusinessSubscriptionsReportsApi, getBusinessUnredemptionReportsApi } from "../utils/apis/report";
import { REPORT_OPTIONS } from "../utils/Const";
import { exportToCSV, setDefaultDate } from "../utils/helper";

const initTable = {
  isLoading:false,
  tableData: null,
  pagination: null,
  filters: {
    end_date : new Date(), 
    start_date: setDefaultDate()
  },
  currentPage: 1,
  pageSize: 10,
}

const initialState = {
  isLoading: false,
  currentTable: REPORT_OPTIONS[0],
  purchaseTable: initTable,
  subscriptionsTable: initTable,
  subscriptionsCurrentTable: initTable,
  incentiveTable: initTable,
  unredemptionTable:initTable,
  newsubscriptionsTable:initTable,
  referTable:initTable,
}

export const getBusinessSubscriptionsCurrent = createAsyncThunk('business/subscriptions/current', getBusinessSubscriptionsCurrentApi)
export const getBusinessSubscriptionsReports = createAsyncThunk('business/subscriptions/reports', getBusinessSubscriptionsReportsApi)
export const getBusinessPurchaseReports = createAsyncThunk('business/purchase/reports', getBusinessPurchaseReportsApi)
export const getBusinessIncentiveReports = createAsyncThunk('business/incentive/reports', getBusinessIncentiveReportsApi)
export const getBusinessUnredemptionReports = createAsyncThunk('business/unredemption/reports', getBusinessUnredemptionReportsApi)
export const getBusinessNewSubscriptionsReport = createAsyncThunk('business/newsubscriptions/reports', getBusinessNewSubscriptionsReportApi)
export const getBusinessReferReport = createAsyncThunk('business/refer/reports', getBusinessReferReportApi)

const reportSlice = createSlice({
  name: "businessSlice",
  initialState,
  reducers:{
    setLoader: (state, {payload}) => {
      state.isLoading = payload
    },
    setDateFilter: (state, {payload}) => {
      if(payload?.table) {
        state[payload?.table].filters = payload?.filter ? {
          ...state[payload?.table].filters,
          ...payload?.filter
        } : null;
      }
    },
    getDateFilter: (state, {payload}) => {
      if(payload?.table) {
        return state[payload?.table].filters
      }
      return null;
    },
    clearDateFilter: (state, {payload}) => {
      if(payload?.table) { 
        state[payload?.table].filters = null;
      }
    },
    setDateDefault: (state, {payload}) => {
      if(payload?.table) { 
        state[payload?.table].filters = {
          end_date : new Date(), 
          start_date: setDefaultDate()
        };
      }
    },
    setTableType: (state, {payload}) => {
        state.currentTable = payload
    },
    changeTablePage: (state, {payload}) => {
      if(payload?.table) {
        state[payload?.table].currentPage = payload?.page;
      }
    },
    changeTableSize: (state, {payload}) => {
      if(payload?.table) {
        state[payload?.table].pageSize = payload?.pageSize;
      }
    },
    resetTable: (state, {payload}) => {
      if(payload?.table) {
        state[payload?.table] = initTable;
      }
    },
  },
  extraReducers: {
    [getBusinessSubscriptionsCurrent.pending]: (state) => {
      state.subscriptionsCurrentTable.isLoading = true;
    },
    [getBusinessSubscriptionsCurrent.fulfilled]: (state, { payload }) => {
      state.subscriptionsCurrentTable.isLoading = false;
      state.subscriptionsCurrentTable.tableData = payload.result.length === 0 ? [] : payload.result[0];
      state.subscriptionsCurrentTable.pagination = payload?.paginaton || false;
    },
    [getBusinessSubscriptionsCurrent.rejected]: (state) => {
      state.subscriptionsCurrentTable.isLoading = false;
    },
    [getBusinessSubscriptionsReports.pending]: (state) => {
      state.subscriptionsTable.isLoading = true;
    },
    [getBusinessSubscriptionsReports.fulfilled]: (state, { payload }) => {
      state.subscriptionsTable.isLoading = false;
      state.subscriptionsTable.tableData = payload.result.length === 0 ? [] : payload.result[0];
      state.subscriptionsTable.pagination = payload?.paginaton || false;
    },
    [getBusinessSubscriptionsReports.rejected]: (state) => {
      state.subscriptionsTable.isLoading = false;
    },

    [getBusinessPurchaseReports.pending]: (state) => {
      state.purchaseTable.isLoading = true;
    },
    [getBusinessPurchaseReports.fulfilled]: (state, { payload }) => {
      state.purchaseTable.isLoading = false;
      state.purchaseTable.tableData = payload.result.length === 0 ? [] : payload.result[0];
      state.purchaseTable.pagination = payload?.paginaton || false;
    },
    [getBusinessPurchaseReports.rejected]: (state) => {
      state.purchaseTable.isLoading = false;
    },

    [getBusinessIncentiveReports.pending]: (state) => {
      state.incentiveTable.isLoading = true;
    },
    [getBusinessIncentiveReports.fulfilled]: (state, { payload }) => {
      state.incentiveTable.isLoading = false;
      state.incentiveTable.tableData = payload.result.length === 0 ? [] : payload.result[0];
      state.incentiveTable.pagination = payload?.paginaton || false;
    },
    [getBusinessIncentiveReports.rejected]: (state) => {
      state.incentiveTable.isLoading = false;
    },
    [getBusinessUnredemptionReports.pending]: (state) => {
      state.unredemptionTable.isLoading = true;
    },
    [getBusinessUnredemptionReports.fulfilled]: (state, { payload }) => {
      state.unredemptionTable.isLoading = false;
      state.unredemptionTable.tableData = payload.result[0]
      state.unredemptionTable.pagination = payload?.paginaton || false;
    },
    [getBusinessUnredemptionReports.rejected]: (state) => {
      state.unredemptionTable.isLoading = false;
    },

    [getBusinessNewSubscriptionsReport.pending]: (state) => {
      state.newsubscriptionsTable.isLoading = true;
    },
    [getBusinessNewSubscriptionsReport.fulfilled]: (state, { payload }) => {
      state.newsubscriptionsTable.isLoading = false;
      state.newsubscriptionsTable.tableData = payload.result[0]
      state.newsubscriptionsTable.pagination = payload?.paginaton || false;
    },
    [getBusinessNewSubscriptionsReport.rejected]: (state) => {
      state.newsubscriptionsTable.isLoading = false;
    },

    
    [getBusinessReferReport.pending]: (state) => {
      state.referTable.isLoading = true;
    },
    [getBusinessReferReport.fulfilled]: (state, { payload }) => {
      state.referTable.isLoading = false;
      state.referTable.tableData = payload.result.length === 0 ? [] : payload.result[0];
      state.referTable.pagination = payload?.paginaton || false;
    },
    [getBusinessReferReport.rejected]: (state) => {
      state.referTable.isLoading = false;
    },
  },
});

export const {
  setLoader,
  setDateFilter,
  getDateFilter,
  clearDateFilter,
  setDateDefault,
  changeTablePage,
  changeTableSize,
  resetTable,
  setTableType,
} = reportSlice.actions;

export default reportSlice.reducer;