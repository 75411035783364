import classNames from 'classnames';
import { useField } from 'formik'
import React, { useState } from 'react'

const FileUploader = ({name, setFieldValue, className, errorMessage, label, validFileTypes}) => {
  const [field, meta] = useField(name);
  const [fileError, setFileError] = useState(null);

  if(!name){
    return <p className='text-red-500'> name prop is required. </p>
  }

  if(!setFieldValue){
    return <p className='text-red-500'> setFieldValue prop is required. </p>
  }

  if(validFileTypes && !Array.isArray(validFileTypes)){
    return <p className='text-red-500'> validFileTypes must be array. </p>
  }

  return (
    <div className="flex text-left flex-col gap-[14px] xl:gap-[30px] 2xl:gap-[35px]">
      <label 
        className={classNames("cursor-pointer flex items-center justify-center", className || "w-full  border-[5px] border-dashed border-gray-500 p-6 min-h-[150px] text-white text-sm xl:text-[28px] 2xl:text-[32px] font-medium tracking-tight pb-2 lg:pb-2 2xl:pb-[20px]")}>
        {field.value ?
          <span className='truncate px-3' >{field.value?.name || field.value }</span> 
          :
          <span>{label || "Upload File"}</span>}
        <input
          hidden
          type="file"
          name={name}
          onChange={(event) => {
            if(validFileTypes) {
              if (validFileTypes.includes(event.currentTarget.files[0].type)) {
                setFileError(null)
                setFieldValue(name, event.currentTarget.files[0])
              } else {
                setFieldValue(name, null)
                setFileError(errorMessage || "Please select valid file.")
              }
            }else {
              setFileError(null)
              setFieldValue(name, event.currentTarget.files[0])
            }
          }}
        />
      </label>
      {fileError ? <span className='text-red-500'>{fileError}</span> : meta?.error ? <span className='text-red-500'>{meta?.error}</span> : null}
    </div>
  )
}

export default FileUploader