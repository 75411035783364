import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { getUserProfileApi, getUserTransactionsApi, userReferFriendApi } from "../utils/apis/users";
import { showErrorToast, showSuccessToast } from "../utils/helper";

const initialState = {
  isLoading: false,
  referLoading: false,
  userData: null,
  transactionData: null,
  pagination: null,
}

export const getUserProfile = createAsyncThunk('user/getProfile', getUserProfileApi)
export const getUserTransactions = createAsyncThunk('user/transactions', getUserTransactionsApi)
export const userReferFriend = createAsyncThunk('user/referFriend', userReferFriendApi)


const userSlice = createSlice({
  name: "userSlice",
  initialState,
  extraReducers: {
    [getUserProfile.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserProfile.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.userData = payload.result[0]
    },
    [getUserProfile.rejected]: (state) => {
      state.isLoading = false;
    },
    [getUserTransactions.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserTransactions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.transactionData = payload.result[0]
      state.pagination = payload?.paginaton || false;
    },
    [getUserTransactions.rejected]: (state) => {
      state.isLoading = false;
    },
    [userReferFriend.pending]: (state) => {
      state.referLoading = true;
    },
    [userReferFriend.fulfilled]: (state, { payload }) => {
      state.referLoading = false;
      showSuccessToast(payload?.message || "Invitation sent successfully.");
    },
    [userReferFriend.rejected]: (state, { payload }) => {
      state.referLoading = false;
      showErrorToast(payload|| "somethink went wrong.");
    },
  },
});

export default userSlice.reducer;