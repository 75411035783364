import React from 'react'

const UserManagement = () => {
    return (
        <div>
            <div className='text-white text-4xl whitespace-nowrap'>
                User Management (Businesses)
            </div>
        </div>
    )
}

export default UserManagement