import React, { useEffect, useState } from "react";
import CommonTable from "./CommonTable";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Arrow } from "../icons";
import {
  changeTablePage,
  getBusinessPurchaseReports,
  getBusinessSubscriptionsCurrent,
  getBusinessSubscriptionsReports,
} from "../../Redux/reportSlice";
import { formatDate } from "../../utils/helper";
const SubscriberReport = () => {
  const dispatch = useDispatch();
  const {
    currentTable,
    isLoading,
    pageSize,
    currentPage,
    pagination,
    filters,
    tableData,
  } = useSelector((state) => ({
    currentTable: state.reportSlice.currentTable,
    isLoading: state.reportSlice.subscriptionsCurrentTable.isLoading,
    pageSize: state.reportSlice.subscriptionsCurrentTable.pageSize,
    currentPage: state.reportSlice.subscriptionsCurrentTable.currentPage,
    pagination: state.reportSlice.subscriptionsCurrentTable.pagination,
    filters: state.reportSlice.subscriptionsCurrentTable.filters,
    tableData: state.reportSlice.subscriptionsCurrentTable.tableData,
  }));

  const columns = React.useMemo(
    () => [
      {
        Header: "Serial No",
        id: "serial_no",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Business",
        id: "to_user",
        accessor: "to_user",
      },
      {
        Header: "Owner's Email id",
        id: "email",
        accessor: "email",
      },
      {
        Header: "Personal points",
        id: "personal_point",
        accessor: (row) => {
          if (Number(row?.personal_point) === 0) return row?.personal_point;
          else {
            if (Number(row?.personal_point).toFixed(1) % 1 === 0)
              return parseInt(Number(row?.personal_point).toFixed(1));
            else {
              return parseFloat(Number(row?.personal_point).toFixed(1));
            }
          }
        },
        // accessor: (row) => {
        //   return Number(row?.personal_point) === 0 ? row?.personal_point :  Number(row?.personal_point).toFixed(1);
        // },
      },
      {
        Header: "Subscription amount",
        id: "subscription_amount",
        accessor: (row) => {
          if (Number(row?.subscription_amount) === 0)
            return row?.subscription_amount;
          else {
            if (Number(row?.subscription_amount).toFixed(1) % 1 === 0)
              return parseInt(Number(row?.subscription_amount).toFixed(1));
            else {
              return parseFloat(Number(row?.subscription_amount).toFixed(1));
            }
          }
        },
        // accessor: (row) => {
        //   return Number(row?.subscription_amount) === 0 ? row?.subscription_amount :  Number(row?.subscription_amount).toFixed(1);
        // },
      },
    ],
    []
  );
  const [data, setData] = useState(null);
  const handlePageClick = (page) => {
    dispatch(
      changeTablePage({ table: currentTable.value, page: page.selected + 1 })
    );
  };
  useEffect(() => {
    dispatch(getBusinessSubscriptionsCurrent());
  }, []);
  useEffect(() => {
    const start_date =
      filters?.start_date && filters?.start_date !== "null"
        ? formatDate(filters?.start_date)
        : null;
    const end_date =
      filters?.end_date && filters?.end_date !== "null"
        ? formatDate(filters?.end_date)
        : null;
    dispatch(
      getBusinessSubscriptionsCurrent({
        page: currentPage,
        pageSize,
        start_date,
        end_date,
      })
    );
  }, [filters, currentPage, pageSize, dispatch]);
  return (
    <div className="mt-[36px] lg:mt-[50px] 4xl:mt-[56px] w-full">
      {Array.isArray(tableData) && (
        <>
          <CommonTable
            columns={
              tableData ? columns : [{ Header: "", accessor: "no_data" }]
            }
            data={tableData || [{ no_data: "No Data" }]}
            HeaderClasses="bg-[#040404] text-[#DD69AA]"
            HeadingClasses="relative py-[10px] px-[15px] 2xl:pr-[30px] 2xl:pl-0 whitespace-nowrap text-[16px] 2xl:text-[20px] leading-[16px] 2xl:leading-[24px] font-[500]  -tracking-[0.02em]"
            cellDefaultStyle="text-[16px] 2xl:text-xl leading-[16px] 2xl:leading-[36.33px] px-[15px] 2xl:pr-[30px] 2xl:pl-0 font-normal py-[10px] 2xl:py-[9px] -tracking-[2%] text-center"
            tableClasses="w-full rounded-[20px] overflow-hidden"
            BodyClasses="text-[#A6A6A6] bg-[#101010]"
            containerClasses="h-max overflow-auto"
            trunctateCells={["to_user", "email"]}
            headerClasses={{
              fromTo: { textAlign: "left" },
            }}
            cellTextClassName={{
              fromTo: { justifyContent: "left", width: "100%" },
            }}
            cellClasses={{
              timestamp: { color: "#A6A6A6" },
            }}
            isLoading={isLoading}
          />
          {pagination && (
            <div className="flex justify-center md:justify-end h-max items-center gap-12">
              <ReactPaginate
                breakLabel=".........."
                nextLabel={
                  <button disabled={!pagination?.next_link} className="group">
                    <div className="bg-[#DD69AA] md:py-[9px] px-3 md:h-[30px] py-1 group-disabled:bg-gray-500 rounded-[3px] ml-2 md:ml-1 md:rounded-[10px] hover:bg-pink-500">
                      <Arrow className="text-white group-hover:text-black rotate-180" />
                    </div>
                  </button>
                }
                pageRangeDisplayed={1}
                onPageChange={handlePageClick}
                forcePage={currentPage - 1}
                pageCount={pagination?.total_pages || 1}
                marginPagesDisplayed={2}
                previousLabel={
                  <button
                    className="group"
                    disabled={!pagination?.previous_link}
                  >
                    <div className="bg-[#DD69AA] group-disabled:bg-gray-500 md:py-[9px] px-3 md:h-[30px] py-1 group rounded-[3px] mr-2 md:mr-1 md:rounded-[10px] hover:bg-pink-500">
                      <Arrow className="text-white group-hover:text-black" />
                    </div>
                  </button>
                }
                renderOnZeroPageCount={1}
                nextLinkClassName="leading-none flex"
                previousLinkClassName="leading-none flex"
                containerClassName={
                  "flex pt-[17px] pb-[25px] md:py-8 justify-center items-center gap-x-[3px] md:gap-x-1 leading-none"
                }
                pageClassName="cursor-pointer md:rounded-[10px] md:min-w-[30px] md:h-[30px] md:flex md:justify-center md:items-center after:content-[','] last:bg-blue-500 after:ml-1 after:text-[#979998] md:after:content-none"
                pageLinkClassName="font-normal md:px-2 md:py-1 h-full w-full text-center text-[14px] md:text-sm leading-[18.87px] text-[#979998] -tracking-tight"
                breakClassName="text-[#979998] tracking-[2px] md:tracking-[3px] 2xl:tracking-[5px]"
                activeLinkClassName="text-[#FFFFFF]"
                activeClassName="md:bg-[#DD69AA]"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SubscriberReport;
