export const Blocks = ({className}) => {
  return (
    <svg
      className={className}
      // width="18"
      // height="18"
      width="16"
      height="16"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10H7C7.55 10 8 9.55 8 9V1C8 0.45 7.55 0 7 0L1 0C0.45 0 0 0.45 0 1L0 9C0 9.55 0.45 10 1 10ZM1 18H7C7.55 18 8 17.55 8 17V13C8 12.45 7.55 12 7 12H1C0.45 12 0 12.45 0 13L0 17C0 17.55 0.45 18 1 18ZM11 18H17C17.55 18 18 17.55 18 17V9C18 8.45 17.55 8 17 8H11C10.45 8 10 8.45 10 9V17C10 17.55 10.45 18 11 18ZM10 1V5C10 5.55 10.45 6 11 6H17C17.55 6 18 5.55 18 5V1C18 0.45 17.55 0 17 0L11 0C10.45 0 10 0.45 10 1Z"
        fill="#DD69AA"
      />
    </svg>
  );
}