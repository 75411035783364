import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilter, getRewardTypes, setCurrentPage, setTableFilter } from '../Redux/commonSlice';
import { redirect, useLocation } from 'react-router-dom';
import { getBusinesses } from '../Redux/businessSlice';

import axios from 'axios';
import { toast } from 'react-hot-toast';

export const getAccessToken = () => {
  return Cookies.get("crypt-access");
}

export const setAccessToken = (token) => {
  return Cookies.set("crypt-access", token);
}

export const removeAccessToken = (token) => {
  return Cookies.remove("crypt-access");
}

export const logout = () => {
  Cookies.remove("crypt-access");
  Cookies.remove("crypt-refresh");
  Cookies.remove("is-admin");
}

export const capitalize = (value) =>  {
  if(typeof value === 'string' && value.length > 1) {
    return value[0].toUpperCase()+value.slice(1);
  }
  return null;
}

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const options = ['Active', 'Inactive'];

  const handleOptionSelect = e => {
    dispatch(setCurrentPage(1));
    dispatch(setTableFilter({value:e.target.id, targetTable: location?.pathname}))
  };
  return (
    <div className="bg-[#303030] rounded-[10px] text-white min-w-[180px] focus-visible:outline-none md:min-w-[200px] 2xl:min-w-[240px]">
      <p
        id=''
        className="border-b w-full hover:bg-[#DD69AA] cursor-pointer border-solid px-[18px] md:px-5 py-2 md:py-[10px] 2xl:py-[17px] text-left border-[#545557] tex-sm md:text-[18px] 2xl:text-[20px]"
        onClick={handleOptionSelect}
      >
        All
      </p>
      {options.map((option, i) => (
        <p
          key={i}
          id={option}
          onClick={handleOptionSelect}
          className="text-left px-[18px] md:px-5 py-2 md:py-[10px] 2xl:py-[17px] hover:bg-[#DD69AA] cursor-pointer w-full last:border-none border-b border-solid tex-sm md:text-[18px] 2xl:text-[20px] border-[#545557]"
        >
          {option[0].toUpperCase()+option.slice(1)}
        </p>
      ))}
    </div>
  );
}

export const handleNumberOnly = (e, setFieldValue) => {
  const value = e.target.value.replace(/\D/g, "");
  setFieldValue(e.target.name, value);
};

export const formatDate = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  return [year, month, day].join('-');
}

export const testExcel = async ({fileName, url}) => {
  const headers = {
    'Content-Type': 'blob',
    Authorization: `Bearer ${getAccessToken()}`,
  };
  const config = {
    method: "GET",
    url: process.env.REACT_APP_PUBLIC_baseURL+url,
    responseType: "arraybuffer",
    headers,
  };
  try {
    const response = await axios(config);
    const outputFilename = `${fileName}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
      throw Error(error);
  }
}

export const handleFloatOnly = (e, setFieldValue) => {
  const value = e.target.value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
  const valueArray = value.split('.');
  valueArray.length > 1 && valueArray[1].length > 1 ?
  e.preventDefault() :
  setFieldValue(e.target.name, value);
};

export const showErrorToast = (msg) => {
  toast.error(msg, {
    duration: 6000,
    className: "mt-[80px] !text-[#DD69AA] !border !border-[#DD69AA] !bg-black"
  })
}

export const showSuccessToast = (msg) => {
  toast.success(msg, {
    duration: 6000,
    className: "mt-[80px] !text-[#DD69AA] !border !border-[#DD69AA] !bg-black"
  })
}

export const setDefaultDate = () => {
  var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
  return new Date("001/"+month+'/'+year);

}