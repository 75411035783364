import classNames from "classnames";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { openModal, setInstructionsModal } from "../../../Redux/commonSlice";
import AdminHeader from "../../layout/AdminHeader";
import { UserLayout } from "../../layout/UserLayout";

const SupportCard = ({children, isClickable = false, content}) => {
  const dispatch = useDispatch();
  return (
    <>
      <div
        onClick={() => isClickable && dispatch(setInstructionsModal({ isVisible: true, content }))}
        className={classNames("text-sm 2xl:text-xl 4xl:text-xl border bg-[#101010] border-solid border-gray-500 px-5 py-4 md:px-10 md:h-[100px] 2xl:h-[160px]  flex items-center rounded-2xl hover:scale-105 transition-all duration-300 hover:border-pink-theme cursor-pointer")}
      >
        <h3 className="support-text max-w-lg md:max-w-none">{children}</h3>
      </div>
    </>
  );
  };
 
const ActivateCardInstructions = () => {
  const DocPoint = ({children, type}) => (<p className="text-sm grid grid-cols-[16px_auto] gap-x-2"> <span className={classNames("mt-[6px] rounded-full bg-pink-theme block h-3 w-3", {"!h-2 !w-2 !mt-2" : type === "small"})}></span>{children}</p>)
  return (
  <div className="text-white">
    <div className="flex flex-col gap-y-3 justify-start text-left max-w-2xl">
      <DocPoint>Your new Infinity Rewards (provided by Incendia) Prepaid Mastercard is here;</DocPoint>
      <DocPoint>What you need to do.</DocPoint>
      <DocPoint>Sign the back of your card, make sure you use a permanent ink pen.</DocPoint>
      <DocPoint>Register your card by going to www.incendiapayments.com and complete your card holder personal information, you will need to enter;</DocPoint>
      <div className="ml-10 mb-3">
        <DocPoint type="small">A User name e.g. email address</DocPoint>
        <DocPoint type="small">Email address, then confirm email address</DocPoint>
        <DocPoint type="small">Phone number</DocPoint>
        <DocPoint type="small">Click the activation email received</DocPoint>
        <DocPoint type="small">Create a password</DocPoint>
        <DocPoint type="small">Confirm password</DocPoint>
        <DocPoint type="small">Create a google authenticator login on your phone using the onscreen prompt</DocPoint>
      </div>
      <DocPoint> Log in to incendiapayments.com using your newly created account details (above).</DocPoint>
      <DocPoint>Read and Agree to the Terms and Conditions.</DocPoint>
      <DocPoint>Set up a secret question and answer.</DocPoint>
      <DocPoint>Unfreeze your card.</DocPoint>
      <DocPoint>Reveal your PIN.  You can change your PIN online or using an ATM</DocPoint>
      <DocPoint>Use your card.  You can use your PIN in conjunction with your card to make purchases without cash wherever you see the MasterCard logo, wherever the merchant is equipped with terminals capable of handling PIN’s.  You can obtain cash from an ATM should your card allow.</DocPoint>
      <DocPoint>You can make contactless payments up to £100.00 using your card.  You will need to use Chip and PIN first before you can make contactless payments.</DocPoint>
    </div>
    <div className="mt-10">
      <p className="max-w-2xl mx-auto text-xs">
        Infinity Protect UK Ltd is a company incorporated in England & Wales that is registered under the company number: 13603566 
        Registered Office Address:  1st & 2nd Floor 80 High Street, Yarm, North Yorkshire, United Kingdom, TS15 9AP
        Website: www.infinityprotect.co.uk Tel.no: 03301900456 Email: Info@Infinityprotect.co.uk
      </p>
    </div>
  </div>
  );
}

const UserSupport = () => {
  return (
    <UserLayout>
      <AdminHeader
        className=""
        showControls={false}
        type="support"
        title="Support"
      />
      <div className="pb-4 flex justify-center items-center">
        <div className="text-white flex flex-col gap-y-6 w-max sm:w-full h-full pt-10 ">
          <SupportCard isClickable={true} content={<ActivateCardInstructions />} >
            <p className="text-pink-theme">How to Activate your card ?</p>
          </SupportCard>
          <SupportCard>
            <p>
              If your card is lost or stolen you can freeze your card by logging
              into your account on <a href="https://www.incendiapayments.com" className="text-pink-theme" >www.incendiapayments.com</a>. Also contact Incendia
              on <a href="tel:+44 203 835 5454" className="text-pink-theme" >+44 203 835 5454</a> or <a href="https://www.incendiapayments.com" className="text-pink-theme" >www.incendiapayments.com</a>.
            </p>
          </SupportCard>
          <SupportCard>
            <div className="lg:flex">
            <p>For any Points related queries please contact us on </p>
            <a href="mailto:info@infinityrewards.co.uk" className="text-pink-theme ml-1" >info@infinityrewards.co.uk</a>.
            </div>
          </SupportCard>
          <SupportCard>
            <p>
              Your membership with Infinity Rewards also gives you Gold level
              access to the Mutually Together Group membership. Here you will find
              an array of Partner Services to help your business. Please contact 
              <a href="mailto:adam@mtgroup.uk" className="text-pink-theme" > info@mtgroup.uk </a> to receive your Log In details.
            </p>
          </SupportCard>
        </div>
      </div>
    </UserLayout>
  );
};

export default UserSupport;
