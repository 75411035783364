import { getRedeemptionData, getRequestPointsData } from "../../Redux/redeemptionSlice";
import ApiMiddleware from "../ApiMiddleware";

export const getRedeemptionDataApi = async (params, { rejectWithValue }) => {
    try {
      const response = await ApiMiddleware.get(`/admin/get/redeem/requests/`);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw rejectWithValue(error);
      }
      throw rejectWithValue(error.response.data.message);
    }
  }

  export const redemptionApproveApi = async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await ApiMiddleware.put(`/admin/approve/redeem/requests/`, params);
      dispatch(getRedeemptionData());
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw rejectWithValue(error);
      }
      throw rejectWithValue(error.response.data.message);
    }
  }

  export const redemptionRejectApi = async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await ApiMiddleware.put(`/admin/reject/redeem/requests/`, params);
      dispatch(getRedeemptionData());
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw rejectWithValue(error);
      }
      throw rejectWithValue(error.response.data.message);
    }
  }
  
  export const getRequestPointsDataApi = async (params, { rejectWithValue }) => {
    try {
      const response = await ApiMiddleware.get(`/admin/get/business/points/requests/`);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw rejectWithValue(error);
      }
      throw rejectWithValue(error.response.data.message);
    }
  }

  export const requestPointApproveApi = async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await ApiMiddleware.put(`/admin/approve/business/points/requests/`, params);
      dispatch(getRequestPointsData());
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw rejectWithValue(error);
      }
      throw rejectWithValue(error.response.data.message);
    }
  }

  export const requestPointRejectApi = async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await ApiMiddleware.put(`/admin/reject/business/points/requests/`, params);
      dispatch(getRequestPointsData());
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw rejectWithValue(error);
      }
      throw rejectWithValue(error.response.data.message);
    }
  }