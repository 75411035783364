import React, { Children, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { InputField } from "../forms/InputField";
import {
  Home,
  Blocks,
  Profile,
  Logout,
  CloseFilled,
  MobMenu,
  SearchIcon,
  Refer,
  Key,
  BuyPoints,
} from "../icons";
import classNames from "classnames";
import { Link } from "react-router-dom";
import NotifyModal from "../modal/NotifyModal";
import ConfirmationModal from "../modal/ConfirmationModal";
import UserRouteMiddleware from "../UserRouteMiddleware";
import RouteMiddleware from "../RouteMiddleware";
import { logout } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { getUserTransactions } from "../../Redux/userSlice";
import { closeModal, openModal, setCurrentPage } from "../../Redux/commonSlice";
import CustomModal from "../CustomModal";
import CustomerForm from "../../admin/CustomerForm";
import InstructionsModal from "../modal/InstructionsModal";
import BuyBusinessPoints from "../pages/user/BuyBusinessPoints";



export const UserLayout = ({ children }) => {
  const route = useLocation();
  const { pageSize, modal } = useSelector((state) => ({
    pageSize: state.commonSlice.tableData.pageSize,
    modal: state.commonSlice.modal,
  }));
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  const [searchTerm, setSearchTerm] = useState(null);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(closeModal());
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const tabs = useMemo(() => [
    { name: "Profile", icon: Profile, route: "/user/profile" },
    { name: "Transactions", icon: Home, route: "/user/transaction" },
    { name: " Buy Business Points", icon: BuyPoints, type: "div", 
      onClick: () => {
        setShowMobileMenu(false);
        dispatch(openModal({ type: "buy-points" }));
      }  
    },
    { name: "Refer a Friend", icon: Refer, route: "/user/refer-friend" },
    { name: "Support", icon: Blocks, route: "/user/support" },
    { name: "Change Password", icon: Key, route: "/user/change-password" },
  ], []);

  useEffect(() => {
    if (searchTerm !== null) {
      dispatch(
        getUserTransactions({
          page: 1,
          search: searchTerm,
          page_size: pageSize,
        })
      );
      searchTerm === "" && setSearchTerm(null);
    }
    dispatch(setCurrentPage(1));
  }, [searchTerm]);
  return (
    <RouteMiddleware>
      <div className="md:grid md:grid-cols-[205px,auto] 2xl:grid-cols-[290px,auto] font-Sans overflow-hidden relative">
        <div
          className={classNames(
            "max-w-[290px] bg-[#040404] min-h-screen pb-14 lg:pb-0 absolute md:relative md:translate-x-0 z-50 md:z-0 right-0 translate-x-full transition-all duration-200",
            { "translate-x-0": showMobileMenu }
          )}
        >
          <div
            className="absolute top-7 md:hidden right-7 cursor-pointer"
            onClick={handleMobileMenu}
          >
            <CloseFilled className="text-[#DD69AA] h-7 w-7" />
          </div>
          <div className="border-b border-[#FFFFFF]/[10%] pt-32 md:pt-16 xl:pt-20 2xl:pt-32"></div>
          <div className="pt-7 md:pt-5 xl:pt-2 2xl:pt-[46px]">
            {tabs.map((tab, i) => tab?.type === "div" ? (
              <div
                onClick={tab?.onClick}
                className="cursor-pointer flex items-center gap-[14px] py-3 md:py-2 2xl:py-3 px-9 md:pr-0 sm:pl-5 2xl:pl-9  relative"
              >
                <BuyPoints className="text-[#DD69AA] h-[16px] w-[16px]" />
                <p className="text-base leading-7 text-[#DD69AA] font-bold whitespace-nowrap">
                  Buy Business Points
                </p>
              </div>
            ) : (
              <Link key={i} to={tab.route}>
                <div className="flex items-center gap-[14px] py-3 md:py-2 2xl:py-3 px-9 md:pr-0 sm:pl-5 2xl:pl-9 relative">
                  {tab.route === route.pathname && (
                    <div className="text-white h-9 w-1 bg-[#DD69AA] absolute right-0"></div>
                    )}
                    <div>
                  <tab.icon />
                    </div>
                  <div className="text-base leading-7 text-[#DD69AA] font-bold whitespace-nowrap">
                    {tab.name}
                  </div>
                </div>
              </Link>
            ))}
            {/* <div
              onClick={() => {
                setShowMobileMenu(false);
                dispatch(openModal({ type: "buy-points" }));
              }}
              className="cursor-pointer flex items-center gap-[14px] py-3 px-9 md:pr-0 sm:pl-5 2xl:pl-9 relative"
            >
              <BuyPoints className="text-[#DD69AA] h-[18px] w-[18px]" />
              <p className="text-base leading-7 text-[#DD69AA] font-bold whitespace-nowrap">
                Buy Business Points
              </p>
            </div> */}
            <Link
              to="/login"
              onClick={() =>
                dispatch(
                  openModal({
                    type: "confirm",
                    message: "Are sure you want to logout ?",
                    handleConfirm: () => {
                      dispatch(closeModal());
                      logout();
                    },
                  })
                )
              }
            >
              <div className="flex items-center gap-[14px] py-3 md:py-2 2xl:py-3 px-9 md:pr-0 sm:pl-5 2xl:pl-9 relative">
                <Logout className="text-[#DD69AA] h-[17px] w-[17px]" />
                <p className="text-base leading-7 text-[#DD69AA] font-bold whitespace-nowrap">
                  Log out
                </p>
              </div>
            </Link>
          </div>
        </div>
        {/* <div className="bg-[#171717] min-h-screen px-8 box-border md:max-w-[calc(100vw_-_200px)] 2xl:max-w-[calc(100vw_-_290px)] w-full 2xl:px-11 "> */}
        <div className="bg-[#171717] min-h-screen px-8 box-border md:max-w-[calc(100vw_-_200px)]  xl:max-w-[calc(100vw_-_220px)] 2xl:max-w-[calc(100vw_-_290px)] w-full 2xl:px-11 ">
          <div className="flex w-full justify-end items-center pt-[30px] pb-[25px] gap-x-5 md:hidden">
            <div className="cursor-pointer" onClick={handleMobileMenu}>
              <MobMenu className="text-[#DD69AA]" />
            </div>
          </div>
          {children}
        </div>
      </div>
      <NotifyModal />
      <InstructionsModal />
      <CustomModal onClose={hideModal} modal={modal}>
        {modal.type === "confirm" && <ConfirmationModal />}
        {modal.type === "edit-profile" && <CustomerForm type={modal.type} />}
        {modal.type === "buy-points" && <BuyBusinessPoints type={modal.type} />}
      </CustomModal>
    </RouteMiddleware>
  );
};
