export const CloseFilled = ({ className }) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 0.75C4.66125 0.75 0.75 4.66125 0.75 9.5C0.75 14.3387 4.66125 18.25 9.5 18.25C14.3387 18.25 18.25 14.3387 18.25 9.5C18.25 4.66125 14.3387 0.75 9.5 0.75ZM13.2625 13.2625C12.9212 13.6037 12.37 13.6037 12.0288 13.2625L9.5 10.7337L6.97125 13.2625C6.63 13.6037 6.07875 13.6037 5.7375 13.2625C5.39625 12.9212 5.39625 12.37 5.7375 12.0288L8.26625 9.5L5.7375 6.97125C5.39625 6.63 5.39625 6.07875 5.7375 5.7375C6.07875 5.39625 6.63 5.39625 6.97125 5.7375L9.5 8.26625L12.0288 5.7375C12.37 5.39625 12.9212 5.39625 13.2625 5.7375C13.6037 6.07875 13.6037 6.63 13.2625 6.97125L10.7337 9.5L13.2625 12.0288C13.595 12.3612 13.595 12.9212 13.2625 13.2625Z"
        fill="currentColor"
      />
    </svg>
  );
};
