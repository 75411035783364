import RedeemRequestReportTable from "../table/RedeemRequestReportTable";
import { AdminLayout } from "../layout/AdminLayout";
import ApprovalRequestHeader from "../layout/ApprovalRequestHeader";
import { useSelector } from "react-redux";
import BusinessPointsRequestReportTable from "../table/BusinessPointsRequestReportTable";

export const ApprovalRequestReport = () => {
  const { currentTable } = useSelector(state => state.redeemptionSlice);
  return (
    <div>
      <AdminLayout >
        <ApprovalRequestHeader />
        {currentTable?.value === "allRedeemptionData" && <RedeemRequestReportTable />}
        {currentTable?.value === "allRequestedPointsData" && <BusinessPointsRequestReportTable />}
      </AdminLayout>
    </div>
  );
};

export default ApprovalRequestReport;
