import React, { useEffect, useState } from 'react'
import CustomButton from '../forms/CustomButton'
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, removeBusiness } from '../../Redux/commonSlice';
import { useParams } from 'react-router-dom';

const ConfirmationModal = () => {
    const dispatch = useDispatch();
    
    const { isLoading, selectedIds, message, handleConfirm } = useSelector(state => ({
        isLoading: state.commonSlice.isLoading,
        selectedIds: state.commonSlice.tableData.selectedIds,
        message: state.commonSlice.modal.message,
        handleConfirm: state.commonSlice.modal.handleConfirm,
    }));
    const { business_id, member_id } = useParams();

    const [members, setMembers] = useState(null);
    
    const handleSubmit = () => {
        members && business_id ? dispatch(removeBusiness({ business_id, data:{ member_id: members }})): member_id ? dispatch(removeBusiness({member_id, data:{ member_id: [member_id] } })) : dispatch(removeBusiness({ data:{ member_id: members } }));
    }
    
    const handleCancel = () => {
        dispatch(closeModal());
    }
    
    useEffect(() => {
        setMembers(Object.keys(selectedIds).filter(selectedId => selectedIds[selectedId]))
    }, [selectedIds]);

    return (
        <div className='max-w-[300px] lg:max-w-[350px] 2xl:max-w-[450px] px-3 lg:px-0 bg-[#1C1C1C] py-5 lg:py-8 2xl:py-9'>
            <p className='text-sm text-white w-ma mx-auto lg:text-base 2xl:text-[22px] px-4'>{message || "Are sure you want to delete business ?"}</p>
            <div className='flex justify-center gap-3 lg:gap-5 2xl:gap-7 mt-5 2xl:mt-8'>
                <CustomButton
                    loaderSize={20}
                    type='submit'
                    onClick={handleCancel}
                    buttonStyle="w-full max-w-[30%] col-start-3 bg-[#DD69AA] rounded-[10px] text-[12px] py-1 text-white lg:text-sm  2xl:text-lg">
                    Cancel
                </CustomButton >
                <CustomButton
                    showLoader={isLoading}
                    disabled={isLoading}
                    loaderSize={10}
                    onClick={handleConfirm || handleSubmit}
                    type='submit'
                    buttonStyle="w-full max-w-[30%] col-start-4 bg-[#DD69AA] rounded-[10px] text-[12px] py-1 text-white lg:text-sm  2xl:text-lg">
                    Yes
                </CustomButton >
            </div>
        </div>
    )
}

export default ConfirmationModal