import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import {
  closeModal,
  openModal,
  userBusinessPointsRequest,
} from "../../../Redux/commonSlice";
import { UserBusinessPointsRequestValidationSchema } from "../../../utils/FormValidations";
import { handleFloatOnly } from "../../../utils/helper";
import CustomButton from "../../forms/CustomButton";
import { InputField } from "../../forms/InputField";
import { CloseFilled } from "../../icons";

export const BuyBusinessPoints = (type) => {
  const requestInitialValues = { points: "" };
  const dispatch = useDispatch();

  const handleRequestPoint = (values, { resetForm }) => {
    dispatch(
      openModal({
        type: "confirm",
        message: `Are you sure, you would like to purchase ${values.points} business points ?`,
        handleConfirm: () => {
          dispatch(
            userBusinessPointsRequest({ points: Number(values.points) })
          );
          dispatch(closeModal());
          resetForm();
        },
      })
    );
  };

  return (
    <div>
      <div className="flex-1 mt-2  rounded-[20px] overflow-hidden bg-[#101010]">
        <Formik
          initialValues={requestInitialValues}
          validationSchema={UserBusinessPointsRequestValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleRequestPoint}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="px-8 py-4 lg:py-6 xl:py-6 2xl:py-8">
                <div className="flex justify-between gap-12">
                  <p className="text-white mb-3 2xl:mb-5 md:text-[20px] lg:text-[24px] 2xl:text-[32px] md:leading-[24px] lg:leading-[30px] 2xl:leading-[56px] font-[700]">
                    Buy Business Points
                  </p>
                  <button
                    type="button"
                    onClick={() => dispatch(closeModal())}
                    className="focus-visible:outline-none cursor-pointer"
                  >
                    <CloseFilled className="text-[#DD69AA] h-5 w-5 md:h-6 md:w-6 2xl:h-8 2xl:w-8" />
                  </button>
                </div>
                <div className="flex text-left gap-[14px] xl:gap-[30px] 2xl:gap-[35px]">
                  <div className="flex-1">
                    <label className="text-[18px] xl:text-[18px] font-medium tracking-tight text-pink-light pb-2 xl:pb-[20px] block">
                      No. of Points
                      <span className="text-[#DD69AA]">*</span>
                    </label>
                    <InputField
                      inputstyle="w-full text-[#858383] text-sm xl:text-base font-normal tracking-[-0.02em] outline-none
                             py-[8px] xl:py-[8px] 2xl:py-[14px] rounded-[10px] 2xl:rounded-2xl border border-[#FFFFFF]/[10%] bg-transparent  xl:pl-[25px] pl-[15.56px]"
                      borderstyle="w-full text-[#858383] text-sm xl:text-base font-normal tracking-[-0.02em] outline-none
                             py-[8px] xl:py-[8px] 2xl:py-[14px] rounded-[10px] 2xl:rounded-2xl border border-red-800 bg-transparent  xl:pl-[25px] pl-[15.56px]"
                      errorRight={true}
                      type="text"
                      id="points"
                      name="points"
                      onChange={(e) => handleFloatOnly(e, setFieldValue)}
                      placeholder="0000"
                    />
                  </div>
                </div>
                <div>
                  <CustomButton
                    className=""
                    type="submit"
                    loaderSize={20}
                    buttonStyle="w-full  py-[7.5px] xl:py-2 text-sm lg:text-lg 2xl:text-xl 2xl:tracking-tight text-white font-bold rounded-[10px] lg:rounded-[16px] bg-[#DD69AA] mt-6 xl:mt-8 2xl:mt-9 "
                  >
                    Buy Points
                  </CustomButton>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BuyBusinessPoints;
