import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router'
import { clearFilter, clearGlobalSearch, clearSelectedNames, setColumnSort, setCurrentPage, setSelectedIds, setTableFilter } from '../Redux/commonSlice';
import { clearData } from '../Redux/customerSlice';

const RouteListener = ({children}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearSelectedNames());
    dispatch(setColumnSort(null));
    dispatch(clearData())
  }, [location]);
  return (
    <>{children}</>
  )
}

export default RouteListener