export const Home = ({ className }) => {
  return (
    <svg
      className={className}
      // width="20"
      // height="17"
      width="16"
      height="16"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99961 16V11H11.9996V16C11.9996 16.55 12.4496 17 12.9996 17H15.9996C16.5496 17 16.9996 16.55 16.9996 16V8.99997H18.6996C19.1596 8.99997 19.3796 8.42997 19.0296 8.12997L10.6696 0.599971C10.2896 0.259971 9.70961 0.259971 9.32961 0.599971L0.96961 8.12997C0.62961 8.42997 0.83961 8.99997 1.29961 8.99997H2.99961V16C2.99961 16.55 3.44961 17 3.99961 17H6.99961C7.54961 17 7.99961 16.55 7.99961 16Z"
        fill="#DD69AA"
      />
    </svg>
  );
};
