import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { ChangePasswordValidationSchema, ResetPasswordValidationSchema } from '../../utils/FormValidations'
import CustomButton from '../forms/CustomButton'
import { InputField } from '../forms/InputField'
import { AdminLayout } from '../layout/AdminLayout'
import { useDispatch, useSelector } from 'react-redux'
import { setOnlineStatus, userPasswordChange } from '../../Redux/commonSlice'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../utils/helper'

const ChangePassword = () => {
    const { isLoading, isOnline } = useSelector((state) => ({
        isLoading: state.commonSlice.isLoading,
        isOnline: state.commonSlice.isOnline,
    }));
    const initialValues = { current_password: "", password: "" };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleResetSubmit = (values) => {
        dispatch(userPasswordChange(values));
    }
    useEffect(() => {
        if(!isOnline){
            dispatch(setOnlineStatus(true));
            logout();
            navigate('/login');
        }
    }, [isOnline])
    return (
        <AdminLayout>
            <div className='bg-[#171717] flex flex-col items-center h-screen py-[100px] sm:justify-center font-Sans px-5'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={ChangePasswordValidationSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={handleResetSubmit}
                >
                    {({ handleSubmit }) =>
                    (<form onSubmit={handleSubmit} >
                        <div className='max-w-[410px] 2xl:max-w-[528px]'>
                            <div className='text-2xl leading-[45px] tracking-[-0.02em] lg:text-3xl 2xl:text-4xl text-pink-light 2xl:leading-[56px] 2xl:tracking-[-0.02em] font-bold text-left  w-[350px]'>
                                Change Password</div>
                            <div className='text-base pb-1 pt-5 sm:text-sm 2xl:text-2xl font-medium tracking-tight text-pink-light 2xl:pb-5'>
                                Old Password <span className='text-[#DD69AA]'>*</span></div>
                            <InputField
                                inputstyle='w-full text-[#737373] text-xs 2xl:text-xl outline-none py-[14px] 2xl:py-[15px] rounded-2xl border border-[#FFFFFF]/[10%] bg-transparent pl-5 2xl:pl-6 placeholder:text-[#737373]'
                                borderstyle='w-full text-[#737373] text-xs 2xl:text-xl outline-none py-[14px] 2xl:py-[15px] rounded-2xl border border-red-800 bg-transparent pl-5 2xl:pl-6 placeholder:text-[#737373]'
                                type='password'
                                id='current_password'
                                name='current_password'
                                placeholder='Min. 8 characters' />
                            <div className='text-base pb-1 pt-5 sm:text-sm 2xl:text-2xl font-medium tracking-tight text-pink-light 2xl:pb-5'>
                                New Password <span className='text-[#DD69AA]'>*</span></div>
                            <InputField
                                inputstyle='w-full text-[#737373] text-xs 2xl:text-xl outline-none py-[14px] 2xl:py-[15px] rounded-2xl border border-[#FFFFFF]/[10%] bg-transparent pl-5 2xl:pl-6 placeholder:text-[#737373]'
                                borderstyle='w-full text-[#737373] text-xs 2xl:text-xl outline-none py-[14px] 2xl:py-[15px] rounded-2xl border border-red-800 bg-transparent pl-5 2xl:pl-6 placeholder:text-[#737373]'
                                type='password'
                                id='password'
                                name='password'
                                placeholder='Min. 8 characters' />
                            <CustomButton
                                disabled={isLoading}
                                loaderSize={20}
                                showLoader={isLoading}
                                type='submit'   
                                // buttonStyle="mt-7 2xl:mt-9 2xl:w-[528px] w-full py-[14px] 2xl:py-[17px] text-base sm:text-sm xl:text-[30px] 2xl:text-[30px] font-bold rounded-2xl text-pink-light bg-[#DD69AA]">                            
                                buttonStyle="w-full py-[7.5px] 2xl:py-[10px] text-xl tracking-tight text-white xl:text-pink-light font-bold rounded-2xl bg-[#DD69AA] mt-[29.6px] xl:mt-8">   
                                Submit
                            </CustomButton >
                        </div>
                    </form>)}
                </Formik>
            </div >
        </AdminLayout>
    )
}

export default ChangePassword